import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

function Testimonials() {
  const { testimonial, quinn } = useStaticQuery(graphql`
    query {
      testimonial: file(relativePath: { eq: "testimonial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quinn: file(relativePath: { eq: "testimonial-baby.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Testimonials
      </h1>

      <div className="mx-6 md:mx-24 my-12">
        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={testimonial.childImageSharp.fluid}
          alt="BCST in practice"
        />
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “I had the pleasure of getting an in the field cranio session with Ang on one of my recent backpacking trips.  While carrying a pack, I often get a major pinch between my shoulder blades.  So one afternoon in camp, Ang worked her magic on me! (Despite the best efforts of the mosquitoes I might add). The next day, I was able to enjoy a pain free start to the day!  Later in the hike, some soreness returned but it was dull and had shifted to my right shoulder blade.  Fast forward to the next day - again I was pain free for the bulk of the hike with the dull pain returning to the shoulder.  Next day and another hike…. Pain free!! I am so appreciative of her work and readiness to do something in less than ideal circumstances - although, you couldn’t beat the view!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Erin
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “Today I am feeling great! I processed A LOT of emotions yesterday, and I’m sure much of it was from the deep nervous system work that we did on Saturday. Thanks Ang, I can’t recommend your magic enough!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Megan
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “My body had a lot of movement last night. Everything from tummy gurgles and digestive movement, to reduced pain and cellular level activity I could feel! Definitely opened up a lot. I realized how disconnected and dissociated I was becoming. I actually feel like I’m back in my body with some connection again.”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Kaylee
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “BCST is so different from massage, it changes my life. My outlook is different after cranio sessions.”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Chris
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “Cranio with Angela has allowed me to remain grounded through unthinkable times over the past year. Not only do her treatments leave me feeling revitalized and refreshed; I leave feeling empowered and strong knowing that my body has every intention of carrying me through. Words cannot express how grateful I am for her talent and grace &mdash; she&apos;s stuck with me for life!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Ashley
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <Img
          className="my-6 md:my-12 mx-auto"
          style={{ maxWidth: "600px" }}
          fluid={quinn.childImageSharp.fluid}
          alt="Baby Quinn"
        />
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “My little Quinn loved his craniosacral treatment with Ang today.  He has held so much tension in his body from a hard birth and trauma from multiple tests while in the NICU.<br />I am just amazed at how receptive he was to his treatment today, and to experience the shifts his body was making.  I can feel tensions gone in his body, he is moving more freely and can even touch his little feet now!<br />The reset on his nervous system this momma also felt, we were both so relaxed and blissed out after.  Such a powerful tool for babies, children and adults!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Amy
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “I want to highly recommend Angela and her work. I was struggling with grieving the loss of my father, and I was unable to sleep for days. Angela created space and support for me and my healing. The same night as my treatment, I was able to release and sleep for 7 hours. I have continued to progress, and feel the effects of the treatment days later.”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Gail
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “My pain has been eased. I have NO PAIN walking or going down stairs, whereas before cranio I always had pain. I have more flexibility and movement in my leg now then I have had in the last year. I’m so grateful for cranio, it gave me movement again!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Myrna
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “Angela has a gift like no other, that needs to be shouted from the highest mountain and shared abundantly! She helped me recognize many suppressed emotions and feelings that I didn’t fully know existed. These feelings were interrupting my day-to-day life, both physically and emotionally. With her gentle hands and guiding wisdom I was able to process and cultivate my heavy thoughts into healing in order to move forward and improve my personal well-being. After my first cranio session I was hooked! Thank you Angela for your compassion and trust. I look forward to or continued appointments!”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Rona
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “I’m so grateful for you Angela and the safe space you provide for my system to process the trauma.”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Chris
        </cite>
      </div>

      <div className="mx-6 md:mx-24 my-12">
        <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify">
          “My 13 month old son and I saw Angela today. It was such a grounding, healing experience. I felt safe and emotionally held. It was transformative for us.”
        </blockquote>
        <cite className="block mt-2 text-xs font-bold text-right uppercase">
          - Hilary
        </cite>
      </div>
    </div>
  );
}

export default Testimonials;
